import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

export default (props) =>(
    <Link to={props.url}>
        <div className=" h-full max-w-xs sm:max-w-sm text-left md:max-w-xl mx-auto flex lg:inline-block lg:w-56 pb-10 lg:mx-10 xl:mx-20 ">
            <div className="h-48 w-full md:w-56 lg:block bg-cover rounded-t-none rounded-l lg:rounded-t lg:rounded-b-none text-center overflow-hidden">
                <Img fixed={props.img} alt={props.url} /> 
            </div>

            <div className="pl-2 w-full lg:block border-gray-400 border-r border-t border-b rounded-r lg:rounded-b lg:rounded-t-none lg:border-t-0 lg:border-l">
                <div className="text-gray-900 font-bold text-xl mb-2">{props.title}</div>
                <p className="text-gray-700 text-base mb-1 px-1 lg:px-5">{props.info}</p>
                <p className="text-gray-800 text-base text-right mt-9 mr-6 md:mt-16 lg:mr-3 lg:mt-6 lg:mb-2 hover:text-green-600">Learn More</p>
            </div>
        </div>
    </Link>
)