import React from "react"
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../templates/layout";
import Card from "../components/card";
import { PositiveCard } from "../components/sentUpdate";

export default class Page extends React.Component{
    constructor(props){
        super(props);
        this.state={successful:0,};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e){
        this.setState({successful:1});
    }
    render(){
        let showCard = this.state.successful ? <PositiveCard msg="You have been added to the mailing list!"/> : null;
        const data = this.props.data.allContentfulHomePage.nodes[0];
        const banner = data.banner;
        const homepageCardsImported = data.homepageCards;
        const testimonial = data.testimonial;

        return(
            
            <Layout title="Home" des="Welcome to Marcus Moran Insurance! I sell an array of Home, Life, and Finacial services!">
                <div className="">
                    {/*This div is for the image*/}
                    <div className="bg-green-900 pb-6 md:pb-8">      
                        <div className="relative text-center">
                            <Img fluid={banner.sizes} alt="An elderly couple on a bench"/>
                            
                            <div className="hidden w-full h-full absolute bg-black opacity-25" style={{
                                top:"50%",
                                left:"50%",
                                transform: "translate(-50%, -50%)"
                            }}>
                                
                            </div>
                            <div className="absolute" style={{
                                top:"50%",
                                left:"72%",
                                transform: "translate(-50%, -50%)"
                            }}>
                                <div className="block">
                                    <div className="w-48 md:w-full block font-black text-base md:text-2xl lg:text-3xl xl:text-4xl xxl:text-5xl text-white md:text-white italic">
                                        Insuring a sound retirement
                                    </div>
                                    <div className="block">
                                        <Link to="/contact/">
                                            <button className="bg-blue-500 hover:bg-blue-600 border-b-4 border-blue-600 text-white font-bold py-2 px-4 md:py-4 md:px-8 md:text-xl lg:py-6 lg:px-10 rounded">
                                                Get Started
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden xl:block">
                                <div className="w-20 absolute fill-current text-white" style={{
                                top:"100%",
                                left:"50%",
                                transform: "translate(-50%, -50%)"
                            }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 22.06"><title>Asset 3</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect x="9.01" y="-1.55" width="4.04" height="25.15" transform="translate(-4.57 11.03) rotate(-45)"/><path d="M3.57,1.41,20.64,18.49l-2.15,2.15L1.41,3.57,3.57,1.41M3.57,0,0,3.57,18.49,22.06l3.57-3.57L3.57,0Z"/><rect x="13.4" y="9.01" width="25.15" height="4.04" transform="translate(-0.19 21.59) rotate(-45)"/><path d="M33.43,1.41l2.15,2.15L18.51,20.64l-2.15-2.15L33.43,1.41m0-1.41L14.94,18.49l3.57,3.57L37,3.57,33.43,0Z"/></g></g></svg>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <br/>
                    {/*This is for testimonials*/}
                    <div className="md:flex justify-between max-w-xs sm:max-w-sm md:max-w-2xl mx-auto pb-10">
                        <div className="md:max-w-sm">
                            {testimonial.content.content}
                        </div>
                        <br />
                        <div className="flex justify-between md:block py-0 md:py-10">
                            <div className="inline-block md:block md:w-full">
                                -{testimonial.name}
                                <br/>
                                <i>Customer since {testimonial.date}</i>
                            </div>
                            <br className="invisible md:visible"/>
                            <Link to="/testimonials/">
                                <div className="italic text-center text-xs sm:text-base inline-block md:block bg-transparent hover:bg-black text-black hover:text-white py-3 sm:py-2 px-4 border border-black hover:border-transparent rounded">
                                    See More Testimonials
                                </div>
                            </Link>
                        </div>
                    </div>
                    
                    <br/>
                    <br/>
                    {/*Cards*/}
                    <div className="text-center">
                        <Card key={0} url={"/aboutMe/"} img={homepageCardsImported[0].cardPicture.fixed} title={homepageCardsImported[0].name} info={homepageCardsImported[0].info} />
                        <Card key={1} url={"/services/"} img={homepageCardsImported[1].cardPicture.fixed} title={homepageCardsImported[1].name} info={homepageCardsImported[1].info} />
                        <Card key={2} url={"/contact/"} img={homepageCardsImported[2].cardPicture.fixed} title={homepageCardsImported[2].name} info={homepageCardsImported[2].info} />
                    </div>
                    
                    <br/>

                    {/*Join mailing list*/}
                    <div className="block mb-10 text-center bg-gray-100 p-4 mx-2 mb:mx-36 lg:mx-48 xl:mx-64 xxl:mx-72">
                        <h1 className="text-2xl mb:text-4xl mb-4">Join the mailing list!</h1>
                        <h1 className="text-2xl mb:text-4xl mb-4">I write frequent updates on <strong>your</strong> medicare needs!</h1>
                        <iframe width="0" height="0" border="0" name="dummyframe" id="dummyframe" title="dummyframe"></iframe>
                        <form className="" onSubmit={this.handleSubmit} action="#" method="post" target="dummyframe">
                            <div className="text-center">
                                
                                <input className="mx-auto block bg-white focus:outline-0 focus:shadow-outline border border-gray-600 rounded-lg py-2 px-4 md:inline-block w-full max-w-sm md:w-48 appearance-none leading-normal mb-3" placeholder="First Name" name="fName" required/>
                                <input className="mx-auto block bg-white focus:outline-0 focus:shadow-outline border border-gray-600 rounded-lg py-2 px-4 md:inline-block w-full max-w-sm md:w-48 md:ml-4 appearance-none leading-normal mb-3" placeholder="Last Name" name="lName" required />
                            </div>
                            <div className="text-center">
                                <input className="mx-auto block md:inline-block bg-white focus:outline-0 focus:shadow-outline border border-gray-600 rounded-lg py-2 px-4 block w-full max-w-sm appearance-none leading-normal mb-3" placeholder="Email Address" name="email" type="email" required/>
                                <button className="block md:inline-block mx-auto bg-blue-600 rounded py-2 px-10 font-bold text-white md:ml-4"name="sub">Submit</button>
                            </div>
                        </form>
                        {showCard}
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
    query{
        allContentfulHomePage {
            nodes {
              homepageCards {
                cardPicture {
                    fixed(width: 250, height: 250, quality: 100) {
                        ...GatsbyContentfulFixed

                      }
                }
                info
                name
              }
              testimonial {
                name
                date
                content {
                    content
                  }
              }
              banner {
                sizes(maxWidth: 1600, quality: 100) {
                    ...GatsbyContentfulSizes
                }
              }
            }
          }
    }
`

